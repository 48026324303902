<template>
  <div class="wrapper" id="upgrade">
    <ctheader></ctheader>
    <div class="company-screen-container">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="自定义编号/uuid">
          <el-input
            v-model="formInline.custom_no"
            placeholder="自定义编号/uuid"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add" size="small">新增</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="company_screen_data"
        style="width: 100%"
        :header-cell-style="getRowClass"
      >
        <el-table-column prop="custom_no" label="自定义设备编号">
        </el-table-column>
        <el-table-column prop="screen_type" label="类型">
          <template slot-scope="scope">
            {{ scope.row.screen_type == 1 ? "综合数据屏" : "综合数据副屏" }}
          </template>
        </el-table-column>
        <el-table-column prop="uuid" label="uuid"> </el-table-column>
        <el-table-column prop="supervise_name" label="群组名称">
        </el-table-column>
        <el-table-column label="绑定时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | filterSecond }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="unbind(scope.row.id)">
              解绑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :start="formInline.start"
        :total="company_screen_total"
        :page-length="formInline.length"
        :pageCallback="getScreenList"
      ></pagination>
      <el-drawer
        title="设备信息"
        :visible.sync="drawer"
        :direction="direction"
        size="50%"
      >
        <div style="padding:20px">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            class="demo-ruleForm"
            size="small"
          >
            <el-form-item label="设备uuid" prop="uuid">
              <el-input v-model="ruleForm.uuid" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="类型" prop="screen_type">
              <el-select v-model="ruleForm.screen_type" style="width:300px">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in screen_type_list"
                  :key="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关联市场群组" prop="supervise_id">
              <el-select v-model="ruleForm.supervise_id" style="width:300px">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in market_group_list"
                  :key="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="desc">
              <el-input
                type="textarea"
                v-model="ruleForm.remark"
                style="width:300px"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            size="small"
            style="margin-top:12px"
            @click="save"
          >
            保存
          </el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import ctheader from "../../components/ctheader";
import pagination from "../../components/pagination";
import router from "../../router";
import * as URL from "../../modules/URLs";
import APIs from "@/modules/APIs";
import { apiPost } from "../../modules/utils";
import moment from "moment";
import { mapActions } from "vuex";
import * as imageConversion from "image-conversion";

const actions = mapActions(["setAlert"]);

export default {
  name: "",
  components: { ctheader, pagination },
  mixins: [],
  props: {},
  data() {
    return {
      formInline: {},
      company_screen_data: [],
      company_screen_total: 0,
      drawer: false,
      direction: "rtl",
      ruleForm: {},
      rules: {
        uuid: [{ required: true, message: "请输入", trigger: "blur" }],
        screen_type: [{ required: true, message: "请选择", trigger: "change" }],
        supervise_id: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      market_group_list: [],
      screen_type_list: [
        {
          id: 1,
          name: "综合数据屏",
        },
        {
          id: 2,
          name: "综合数据副屏",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getScreenList();
  },
  filters: {
    filterSecond(num) {
      return num ? moment.unix(num).format("YYYY-MM-DD HH:mm:ss") : "";
    },
  },
  methods: {
    ...actions,
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #e9f2f1;font-size:12px;color:#525e6e;border:0;font-weight:normal";
      } else {
        return "";
      }
    },
    //群组列表
    getUpgradeList() {
      this.formInline.action = "lists";
      let $this = this;
      const params = {
        action: "lists",
        start: 0,
        length: 9999,
      };
      apiPost({
        url: APIs.MARKETGROUP,
        data: params,
        success(res) {
          if (res.code === "0") {
            $this.market_group_list = res.data;
          } else {
            $this.market_group_list = [];
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    getScreenList(index = 0) {
      this.formInline.action = "screen.list";
      this.formInline.start = index;
      let $this = this;
      apiPost({
        url: APIs.MARKETGROUP,
        data: $this.formInline,
        success(res) {
          if (res.code === "0") {
            $this.company_screen_data = res.data;
            $this.company_screen_total = res.total;
          } else {
            $this.company_screen_data = [];
            $this.company_screen_total = 0;
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    query() {
      this.formInline.start = 0;
      this.getScreenList();
    },
    add() {
      this.drawer = true;
      this.getUpgradeList();
    },
    save() {
      this.ruleForm.action = "screen.add";
      let $this = this;
      apiPost({
        url: APIs.MARKETGROUP,
        data: $this.ruleForm,
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.drawer = false;
            $this.getScreenList();
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    unbind(val) {
      this.$confirm("是否解绑该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let $this = this;
          const params = {
            action: "screen.edit",
            id: val,
          };
          apiPost({
            url: APIs.MARKETGROUP,
            data: params,
            success(res) {
              if (res.code === "0") {
                $this.setAlert({ msg: res.msg, type: true });
                $this.getScreenList();
              } else {
                $this.setAlert({ msg: res.msg });
              }
            },
            error(res) {
              $this.setAlert({ msg: "获取数据失败" });
            },
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.company-screen-container {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
}
</style>
